/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, FC, useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import {  BrowserRouter as Router} from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor, PersistGate, localsagas } from "./configStore";
import { initSagaMiddleware, States } from "saur-states-management";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import i18n from "saur-states-management/lib/i18n";
import { getTheme, Brands } from "saur-design-sys-web/lib/BrandsConfig";
import AtomsStyles from "saur-design-sys-web/lib/Atoms/Styles";
import MoleculesStyles from "saur-design-sys-web/lib/Molecules/Styles";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import OrganismsStyles from "saur-design-sys-web/lib/Organisms/Styles";
import ResetMeyer from "saur-design-sys-web/lib/Common/ResetMeyer";
import GlobalStyles from "saur-design-sys-web/lib/Common/GlobalStyles";
import GlobalFonts from "saur-design-sys-web/lib/Common/GlobalFonts";
import ResetInputs from "saur-design-sys-web/lib/Common/ResetInputs";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Loader from "saur-design-sys-web/lib/Atoms/Loader/Loader";
import { i18nContext } from "./common/translation";
import Navigation from "./components/Navigation";
import AppStyles from "./AppStyles";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { settingsActions } from "saur-states-management/lib/states/settingsState";

initSagaMiddleware(localsagas);
function setHtmlLang(lang: string): void {
	let htmlElement = document.querySelector("html");
	if (htmlElement) {
		// @ts-ignore
		htmlElement.lang = lang;
	}
}

// it dispatch the theme configuration into all children
const ThemeWrapper: FC<{ children: ReactElement }> = ({ children }) => {
	const theme = useSelector((state: States) => state.settings.theme) as Brands;

	return <ThemeProvider theme={getTheme(theme)}>{children}</ThemeProvider>;
};

// it dispatch the store and localstorage data into all children
const StoreWrapper: FC<{ children: ReactElement }> = ({ children }) => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{children}
		</PersistGate>
	</Provider>
);

// it dispatch translation into all children
const TranslationProvider: FC<{ children: ReactElement }> = ({ children }) => {
	const lang = useSelector(getLanguage) as string;
	const translationInst = i18n(lang);
	setHtmlLang(lang);
	return (
		<i18nContext.Provider value={translationInst}>
			{children}
		</i18nContext.Provider>
	);
};

// Main App
const StoreProvider = () => (
	<StoreWrapper>
		<App />
	</StoreWrapper>
);

const App = () => {
	const dispatch = useDispatch();
	const isProduction = process.env.NODE_ENV === "production";
	const matomoId = useSelector(
		(state: States) => state.settings.params?.MatomoId
	) as number;
	const errorMsg = useSelector((state: States) => state.settings?.errorMsg);
	const loading = useSelector((state: States) => state.settings?.loading);
	const { trackEvent } = useMatomo();
	useEffect(() => {
		dispatch(settingsActions.getBrandData());
	}, []);

	function renderLoading() {
		return (
			<Container className="main-loader">
				<LoadingStyles />
				<Loader />
			</Container>
		);
	}

	function renderApp() {
		return (
			<>
				<Router>
					<Navigation />
				</Router>
			</>
		);
	}

	function switchApp(): any {
		if (isProduction && matomoId && !errorMsg) {
			const instanceMatomo = createInstance({
				urlBase: process.env.REACT_APP_MATOMO_URL as string,
				siteId: matomoId,
			});
			return (
				<MatomoProvider value={instanceMatomo}>{renderApp()}</MatomoProvider>
			);
		} else if (!isProduction) {
			return renderApp();
		} else if (loading) {
			return renderLoading();
		} else if (errorMsg) {
			return renderError();
		}
		return <Container className="undefined-statement" />;
	}

	function renderError() {
		trackEvent({
            category: "Maintenance",
            action: "Redirection",
            name: "Maintenance Page",
        });
		return window.location.assign("/maintenance.html");
	}

	return (
		<ThemeWrapper>
			<>
				<ResetInputs />
				<GlobalFonts />
				<GlobalStyles />
				<ResetMeyer />
				<AtomsStyles />
				<MoleculesStyles />
				<OrganismsStyles />
				<AppStyles />
				<TranslationProvider>{switchApp()}</TranslationProvider>
			</>
		</ThemeWrapper>
	);
};

const LoadingStyles = createGlobalStyle`
.sds-reset-style{
  .main-loader {
    position: fixed;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%; 
    transform: translateX(-50%) translateY(-50%);   
  }
}
`;

export default StoreProvider;
